import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/shang-pin-dw/list',
        method: 'post',
        data
    })
}

//获取商品单位下拉列表
export function getDanWeiSelectList() {
    return request({
        url: '/shang-pin-dw/get-select-list',
        method: 'get',
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/shang-pin-dw/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/shang-pin-dw/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/shang-pin-dw/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/shang-pin-dw/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/shang-pin-dw/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/shang-pin-dw/delete-batch',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/shang-pin-dw/query',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'shang-pin-dw-list',
    component: () => import('@/view/spgl/spdw/ShangPinDWList'),
    name: 'ShangPinDWList',
    meta: {title: '商品单位', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'shang-pin-dw-query',
    component: () => import('@/view/spgl/spdw/ShangPinDWQuery'),
    name: 'ShangPinDWQuery',
    meta: {title: '商品单位查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'shang-pin-dw-remind-list',
    component: () => import('@/view/spgl/spdw/ShangPinDWRemindList'),
    name: 'ShangPinDWRemindList',
    meta: {title: '商品单位提醒', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"商品单位基础列表","methodUrl":"/shang-pin-dw/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"商品单位添加保存","methodUrl":"/shang-pin-dw/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"商品单位获取编辑数据","methodUrl":"/shang-pin-dw/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"商品单位获取详情数据","methodUrl":"/shang-pin-dw/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"商品单位编辑保存","methodUrl":"/shang-pin-dw/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"商品单位删除","methodUrl":"/shang-pin-dw/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"商品单位批量删除","methodUrl":"/shang-pin-dw/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"商品单位独立查询","methodUrl":"/shang-pin-dw/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
*/
