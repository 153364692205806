<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="单位名称" >
                <el-input v-model="search.danWeiMC" size="small" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="danWeiMC" label="单位名称" />
            <el-table-column width="180" prop="tianJiaSJ" label="添加时间" />
            <el-table-column width="100" align="center" label="操作" fixed="right">
                <el-button slot-scope="{row}" type="primary" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 详情页 -->
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/spgl/ShangPinDW";
    import XTableBase from "@/components/x/XTableBase";
    import Detail from "@/view/spgl/spdw/ShangPinDWDetail";

    export default {
        name: "ShangPinDWQuery",
        mixins: [XTableBase],
        components: {Detail},
        data() {
            this.refreshService = service.query;
            return {
                search: {
                    danWeiMC: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>